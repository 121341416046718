import { merge } from "lodash-es";
import defaultPalette from "./palette";

const colorsWithPalette = (pal?: typeof defaultPalette) => {
  const palette = pal ? merge({}, defaultPalette, pal) : defaultPalette;
  const colors = {
    ...palette,
    transparent: {
      20: "#00000033",
    },
    primary: {
      dark: palette["blue"][800],
    },
    link: palette["blue"][800],
    inputBackground: palette["whiteAlpha"][900],
    black: palette["defaultBlack"],
    white: palette["defaultWhite"],
    text: palette["defaultBlack"],
    background: palette["defaultWhite"],
    backgroundColors: {
      lightBlue: palette["defaultLightBlue"],
    },
    brand: {
      highlight: palette["blue"][500],
      selected: palette["blue"][600],
      color: palette["blue"][800],
      background: palette["gray"][100],
      secondaryBackground: palette["defaultOrange"],
      tertiaryBackground: palette["violet"][50],
      blueHighlight: "#E6EDF1",
      blueSelected: "#e6eef1",
      blue: "#4B6E83",
      blueBackground: "#E6EDF1",
      orangeBackground: "#F07E2660",
      violetBackground: "#D6C8E060",
    },
    alert: {
      background: palette["pink"][50],
      color: palette["pink"][800],
    },
    error: {
      background: palette["red"][50],
      color: palette["red"][800],
    },
    success: {
      background: palette["green"][50],
      color: palette["green"][800],
    },
    warning: {
      background: palette["yellow"][50],
      color: palette["yellow"][800],
    },
    reportColors: {
      account: palette["blue"][50],
      transaction: palette["violet"][50],
      savings: palette["orange"][50],
      income: palette["defaultWhite"],
      participantAccount: palette["blue"][100],
    },
    timeLineChartColors: {
      yAxis: palette["blue"][50],
    },
    timelinePeriodColors: {
      boardSetsEarningCriteria: palette["blue"][800],
      performancePeriod: palette["blue"][300],
      optionVestingPeriod: palette["blue"][200],
      ownershipRequirement: palette["orange"][400],
      sharePurchases: palette["orange"][100],
      measureOfAchievement: palette["pink"][500],
      optionDelivery: palette["green"][300],
      savings: palette["green"][100],
      vestingPeriodRequirement: palette["green"][500],
      subscriptionPeriod: palette["green"][200],
      sharePurchasesEssp: palette["green"][200],
      shareDelivery: palette["violet"][500],
      optionExpirationDate: palette["violet"][400],
      dividendAdjustment: palette["violet"][200],
    },
    sharePriceGraphColors: {
      grid: palette["blue"][50],
    },
    corporateActionTypeColors: {
      bonusIssue: palette["orange"][50],
      bonusShare: palette["blue"][300],
      changeOfTradecode: palette["blue"][200],
      combination: palette["blue"][100],
      dividend: palette["orange"][100],
      capitalReturn: palette["blue"][50],
      shareDividend: palette["defaultWhite"],
      spinOff: palette["green"][100],
      exchange: palette["blue"][200],
      rightDistribution: palette["green"][200],
      subscription: palette["green"][300],
      merger: palette["violet"][500],
      tenderOffer: palette["violet"][400],
      reverseSplit: palette["violet"][200],
      split: palette["blue"][50],
      salesOfSecurity: palette["violet"][50],
      vacuuming: palette["orange"][100],
    },
    badgeColors: {
      bluePill: {
        color: "#4B6E83",
        background: "#E6EDF1",
      },
      greenPill: {
        color: "#428a33",
        background: "#E6F8E3",
      },
      violetPill: {
        color: "#8d669f",
        background: "#F2EDF5",
      },
      redPill: {
        color: "#AB5E78",
        background: "#F2D3DE",
      },
      yellowPill: {
        color: "#807A2A",
        background: "#FAF7CA",
      },
    },
    recentActionsColors: {
      headerBackground: palette["blue"][100],
      background: palette["blue"][50],
    },
    custodyAccountColors: {
      portfolio: palette["blue"][200],
      esspPortfolio: palette["violet"][200],
      accountRow: palette["blue"][50],
    },
    savingsColors: {
      footer: palette["blue"][50],
    },

    graph: {
      blue: "#003C69",
      orange: "#F07E26",
      violet: "#AD91C1",
      green: "#58A54B",
      lightOrange: "#f9b786",
    },
    dateRangeBadge: {
      font: palette["blue"][800],
      border: palette["blue"][300],
    },
    myDetails: {
      cardHeader: palette["blue"][200],
      sidebarBackground: palette["defaultWhite"],
    },
    postLogin: {
      bluePrimary: "#ACC7D760",
      blueSecondary: "#E6EDF1",
      orangePrimary: "#F07E2660",
      orangeCircle: "#F07E26",
      greenText: "#58A54B",
      grayText: "#8DA3B0",
    },
    dashboardHeaders: {
      blue: "#D8E4EC",
      green: "#A8D49C",
      orange: "#F9C69E",
      orangeIcon: "#F07E26",
      violet: "#E8DCEC",
      yellow: "#FFDC9C",
    },
    dashboardCards: {
      blue: "#E8ECF4",
      green: "#E8FCE4",
      orange: "#FFF4EC",
      yellow: "#FFF4DC",
    },
    donutGraph: [
      palette["blue"][800],
      palette["orange"][400],
      palette["violet"][500],
      palette["green"][500],
      palette["pink"][500],
      palette["defaultWhite"],
      "#E6EDF1",
    ],
    shareRegister: {
      blueHeader: "#C0D4DC",
      blueBackground: "#E5EDF0",
      greenHeader: "#B0D4A4",
      greenBackground: "#E6F7E4",
    },
    translucentWhite: "rgba(255, 255, 255, 0.9)",
  };

  return colors;
};

export default colorsWithPalette;
