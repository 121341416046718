import "../styles/globals.css";
import "../public/css/general-sans.css";
import { AppProps } from "next/app";
import Head from "next/head";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { IntlProvider } from "react-intl";
import { gql } from "@apollo/client";
import { useState, Fragment, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import dynamic from "next/dynamic";

import { useQuery } from "@apollo/client";

import getApolloClient from "apollo/client";
import appTheme from "styles/theme";
import otherTheme from "styles/theme2";

import { intlMessageLoader } from "components/intlMessageLoader";
import {
  GetInitialDataQuery,
  GetRumDataQuery,
  GetSupportedLanguagesQuery,
} from "apollo/generatedTypes";

import rumScript from "./rumScript";

import favicon from "public/favicon.ico";

import LoginLayout from "pages/components/LoginLayout";
import ErrorBoundary from "components/ErrorBoundary";
import { useRouter } from "next/router";

const LoggedInContainer = dynamic(() => import("components/LoggedInContainer"));
const ParticipantsApolloProvider = dynamic(
  () => import("components/ParticipantsApolloProvider")
);

const apolloClient = getApolloClient();

const theme = extendTheme(appTheme);

const GET_SESSION_ID = gql`
  query getInitialData {
    sessionId
  }
`;

const GET_RUM_DATA = gql`
  query getRumData {
    getRumAppKey
  }
`;

const GET_SUPPORTED_LANGUAGES = gql`
  query getSupportedLanguages {
    getSupportedLanguages
  }
`;

const useMocks =
  typeof window === "object" && process.env.NEXT_PUBLIC_USE_MOCKS === "true";

if (useMocks) {
  const { worker } = require("apollo/mocks/browser");
  worker.start();
}

function MyApp({ Component, pageProps }: AppProps) {
  const { data, loading, refetch } = useQuery<GetInitialDataQuery>(
    GET_SESSION_ID,
    {
      client: apolloClient,
    }
  );

  const payload = useQuery<GetRumDataQuery>(GET_RUM_DATA, {
    client: apolloClient,
  });

  const { noAuthNeeded } = (Component?.defaultProps as any) ?? {};

  const defaultLocale =
    typeof window !== "undefined"
      ? window.navigator.language.split("-")[0]
      : "en";

  const { loading: languageLoading } = useQuery<GetSupportedLanguagesQuery>(
    GET_SUPPORTED_LANGUAGES,
    {
      client: apolloClient,
      onCompleted: (data) => {
        console.log("SUPPORTED LANGUAGES", data);

        handleLocale(
          data?.getSupportedLanguages?.includes(defaultLocale)
            ? defaultLocale
            : "en"
        );
      },
    }
  );

  const [selectedLocale, handleLocale] = useState(defaultLocale);

  const locale = selectedLocale;
  const [selectedMessages, setMessages] = useState();

  const { sessionId } = data ?? {};

  useEffect(() => {
    const loadMessagesForLocale = async (locale: string) => {
      const loader = intlMessageLoader[locale];
      if (typeof loader === "function") {
        const messages = await loader();
        setMessages(messages);
      }
    };
    loadMessagesForLocale(locale);
  }, [locale]);

  const { getRumAppKey } = payload.data ?? {};

  useEffect(() => {
    if (getRumAppKey) {
      rumScript(getRumAppKey);
    }
  }, [getRumAppKey]);

  const router = useRouter();

  const [selectedTheme, setTheme] = useState(theme);

  const handleTheme = (theme: string) => {
    if (theme === "2") {
      setTheme(otherTheme);
    } else {
      setTheme(appTheme);
    }
  };

  if (loading || payload.loading || languageLoading) return null;

  return (
    <Fragment>
      <Head>
        <title>Allshares</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <link rel="icon" type="image/x-icon" href={favicon.src} />
      </Head>

      <ChakraProvider theme={selectedTheme}>
        {!sessionId ? (
          <IntlProvider locale={locale} messages={selectedMessages}>
            <LoginLayout
              selectedLocale={locale}
              handleLocale={handleLocale}
              handleTheme={handleTheme}
            >
              <ErrorBoundary>
                <Component
                  {...pageProps}
                  refreshInitialData={refetch}
                  locale={locale}
                  apolloClient={apolloClient}
                />
              </ErrorBoundary>
            </LoginLayout>
          </IntlProvider>
        ) : noAuthNeeded && Component.displayName !== "VerifyEmail" ? (
          <IntlProvider locale={locale} messages={selectedMessages}>
            <LoginLayout
              selectedLocale={locale}
              handleLocale={handleLocale}
              handleTheme={handleTheme}
            >
              <ErrorBoundary>
                <Component
                  {...pageProps}
                  refreshInitialData={refetch}
                  locale={locale}
                />
              </ErrorBoundary>
            </LoginLayout>
          </IntlProvider>
        ) : (
          <ParticipantsApolloProvider
            sessionId={sessionId as string}
            key={sessionId}
            handleSessionExpiration={() => {
              router.push("/logout?sessionExpired=true");
            }}
          >
            {Component?.displayName !== "VerifyEmail" ? (
              <LoggedInContainer
                Component={Component}
                pageProps={pageProps}
                initialLocale={locale}
                handleTheme={handleTheme}
                theme={selectedTheme}
              />
            ) : (
              <IntlProvider locale={locale} messages={selectedMessages}>
                <LoginLayout
                  selectedLocale={locale}
                  handleLocale={handleLocale}
                  handleTheme={handleTheme}
                >
                  <ErrorBoundary>
                    <Component
                      {...pageProps}
                      refreshInitialData={refetch}
                      locale={locale}
                    />
                  </ErrorBoundary>
                </LoginLayout>
              </IntlProvider>
            )}
          </ParticipantsApolloProvider>
        )}
      </ChakraProvider>
    </Fragment>
  );
}

export default MyApp;
