import { Box, Flex, Select, Image } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { gql, useQuery } from "@apollo/client";
import getApolloClient from "apollo/client";
import {
  BackgroundDarkening,
  GetLoginpageDataQuery,
} from "apollo/generatedTypes";
import { sortBy } from "lodash-es";
import React from "react";
import { getLanguageFriendlyName } from "@eam/incentive-shared/src/languages";
import { useIntl } from "react-intl";
import BackgroundImage from "./BackgroundImage";

const apolloClient = getApolloClient();

const GET_LOGIN_PAGE_DATA = gql`
  query getLoginpageData($companyBaseUrl: String) {
    getCompany(companyBaseUrl: $companyBaseUrl) {
      id
      logo
      baseUrl
      backgroundDarkening
    }
    getSupportedLanguages
  }
`;

const LoginLayout = ({
  children,
  selectedLocale,
  handleLocale,
  handleTheme,
}: {
  children: React.ReactNode;
  selectedLocale: string;
  handleLocale: (locale: string) => void;
  handleTheme: (theme: string) => void;
}) => {
  const router = useRouter();
  const { company } = router.query;

  const payload = useQuery<GetLoginpageDataQuery>(GET_LOGIN_PAGE_DATA, {
    variables: {
      companyBaseUrl: company ? encodeURIComponent(String(company)) : undefined,
    },
    client: apolloClient,
    errorPolicy: "all",
  });

  const { data } = payload;
  const { logo, backgroundDarkening } = data?.getCompany ?? {};
  const { getSupportedLanguages } = data ?? {};
  const { formatMessage } = useIntl();

  return (
    <Box height="100%" minHeight="100vh">
      <BackgroundImage
        company={company as string}
        backgroundDarkening={backgroundDarkening as BackgroundDarkening}
        isLogin={true}
      />

      <Flex
        alignItems="center"
        background="white"
        height="70px"
        justifyContent="space-between"
        p="6"
        position="sticky"
        top="0"
        zIndex="2"
        borderBottom="1px solid"
        borderBottomColor="gray.200"
      >
        <Flex position="relative" justifyContent="flex-start">
          {logo && (
            <Image
              height="30px"
              src={`data:image/png;base64, ${logo}`}
              alt="logo"
              objectFit="contain"
            />
          )}
        </Flex>

        <Flex justifyContent="flex-end" gap="2">
          <Select
            onChange={(e) => {
              handleTheme(e.target.value);
            }}
            placeholder="Select theme"
            width="240px"
          >
            <option value="1">Theme 1</option>
            <option value="2">Theme 2</option>
          </Select>

          <Select
            value={selectedLocale}
            onChange={(e) => {
              handleLocale(e.target.value);
            }}
          >
            {sortBy(getSupportedLanguages).map((language) => (
              <option value={language} key={language}>
                {formatMessage(getLanguageFriendlyName(language))}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>

      <Box zIndex="1" position="relative" height="calc(100vh - 70px)">
        {children}
      </Box>
    </Box>
  );
};

LoginLayout.defaultProps = {
  noAuthNeed: true,
};
export default LoginLayout;
