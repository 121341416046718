import { defineStyleConfig } from "@chakra-ui/react";

const EAIListItem = defineStyleConfig({
  //   defaultProps: {
  //     size: "md",
  //     variant: "filled",
  //   },
  variants: {
    alert: {
      background: "alert.background",
      color: "alert.color",
    },
    striped: {
      // color: "striped.color",
      _odd: {
        backgroundColor: "table.oddBackground",
      },
      _even: {
        backgroundColor: "table.evenBackground",
      },
    },
  },

  //   baseStyle: {
  //     field: {
  //       color: "input.color",
  //     },
  //   },
});

export default EAIListItem;
