import { extendTheme } from "@chakra-ui/react";
const defaultPalette = extendTheme().colors;

const palette = {
  ...defaultPalette,
  blue: {
    50: "#E5F4FF",
    100: "#B8E0FF",
    200: "#8ACDFF",
    300: "#5CB9FF",
    400: "#2EA5FF",
    500: "#0092FF",
    600: "#0075CC",
    700: "#005799",
    800: "#003C69",
    900: "#001D33",
  },
  green: {
    50: "#e9f9e6",
    100: "#cce8c6",
    200: "#acd7a5",
    300: "#8dc783",
    400: "#6eb761",
    500: "#549e48",
    600: "#407b37",
    700: "#2d5826",
    800: "#193615",
    900: "#011400",
  },
  orange: {
    50: "#ffefdd",
    100: "#fed3b2",
    200: "#f9b786",
    300: "#f49c57",
    400: "#f07f28",
    500: "#d7660f",
    600: "#a84f0a",
    700: "#783805",
    800: "#4a2100",
    900: "#1f0800",
  },
  pink: {
    50: "#FBE9F0",
    100: "#F4C2D5",
    200: "#ED9CBA",
    300: "#E6759E",
    400: "#DF4E83",
    500: "#D82768",
    600: "#AD1F53",
    700: "#82173F",
    800: "#56102A",
    900: "#2B0815",
  },
  violet: {
    50: "#f5eefa",
    100: "#dbcee3",
    200: "#c1aecf",
    300: "#a98ebc",
    400: "#916da8",
    500: "#775490",
    600: "#5c4170",
    700: "#422f50",
    800: "#281c31",
    900: "#0f0814",
  },
  defaultBlack: "#1B1B1B",
  defaultWhite: "#fff",
  defaultLightBlue: "#DFE9EE",
  defaultOrange: "#F07E2660",
  defaultViolet: "#422f50",
};

export default palette;
