import { extendTheme } from "@chakra-ui/react";
import Button from "styles/components/button";
import Drawer from "styles/components/drawer";
import Modal from "styles/components/modal";
import Input from "styles/components/input";
import Badge from "styles/components/badge";
import EaiCard, { EaiCardHeader } from "styles/components/eaicard";
import Table from "styles/components/table";
import Select from "styles/components/select";
import Checkbox from "./components/checkbox";
import WebContentContainer from "./components/webcontentcontainer";
import Banner from "./components/banner";
import EAIListItem from "styles/components/eailistitem";

import colorsWithPalette from "./colors";
import { linearGradient, transparentize } from "polished";
import palette from "./palette";

const colors = colorsWithPalette();

export const theme = extendTheme({
  config: {
    cssVarPrefix: "eai",
  },
  fonts: {
    heading: "Avenir LT",
    body: "Avenir LT",
  },
  colors: {
    ...colors,
    navbar: {
      background: "transparent",
      color: "white",
    },
    chartColors: [
      palette["blue"][700],
      palette["orange"][500],
      palette["green"][500],
      palette["violet"][500],
      palette["blue"][700],
      palette["blue"][400],
    ],
    brand: {
      ...colors.brand,
      dashboardWelcomeBox: {
        background: transparentize(0.2, colors.blue[50]),
        color: "black",
      },
      heading1: "white",
    },
    secondary: {
      background: colors["blue"][50],
      color: "black",
      headerBackground: colors["gray"][100],
    },
    login: {
      background: "white",
      helpdeskBackground: linearGradient({
        colorStops: ["#D0DFE8", "#E0E9EE", "#CDDCE5"],
        toDirection: "to right",
      }).backgroundImage,
      helpdeskDisclaimerBackground: "blue.800",
    },
    input: {
      background: palette["defaultLightBlue"],
      border: palette["defaultLightBlue"],
      color: "black",
    },
    alert: {
      background: colors["orange"][50],
      color: "black",
      headerBackground: colors["orange"][100],
    },
    graph: {
      background: "white",
      color: "black",
      headerBackground: "white",
    },
    neutral: {
      background: colors["blue"][50],
      color: "black",
      headerBackground: "#ACC7d7",
    },
    success: {
      background: colors["green"][50],
      color: "black",
      headerBackground: colors["green"][100],
    },
    navigationButton: {
      color: "white",
      background: colors["orange"][400],
      hoverBackground: colors["orange"][500],
      borderColor: "transparent",
      textTransform: "none",
      _active: {
        bg: colors["orange"][600],
      },
    },

    inActiveNavigationButton: {
      color: "white",
      background: "transparent.20",
      hoverBackground: "orange.500",
      borderColor: "white",
      textTransform: "none",
      _active: {
        bg: "#00000090",
      },
    },
    table: {
      subHeader: palette["blue"][50],
      evenBackground: palette["gray"][50],
      oddBackground: palette["white"],
      rowBorder: palette["blue"][50],
      footerBorder: palette["blue"][800],
      hover: palette["blue"][50],
      header: "#ACC7D7",
      selectedBackground: colors["blue"][50],
      alertBackground: colors["blue"][200],
      activeBackground: colors["gray"][300],
    },
  },
  components: {
    Button,
    Drawer,
    Modal,
    Input,
    Badge,
    EaiCard,
    Table,
    Select,
    Checkbox,
    WebContentContainer,
    Banner,
    EaiCardHeader,
    EAIListItem,
  },

  multiselect: {
    container: {
      border: 0,
      background: "transparent",
    },
    valueContainer: {
      background: "white",
      paddingLeft: 2,
      width: "100%",
      minHeight: "40px",
    },
    control: {
      border: "1px solid",
      borderRadius: 6,
      borderColor: "#eaeaea",
    },
    menu: {
      zIndex: 2,
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
      mt: "2px",
    },
    dropdownIndicator: {
      background: "white",
    },
  },
});

export default theme;
