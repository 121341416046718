import { extendTheme } from "@chakra-ui/react";
import baseTheme from "./theme";
import colorsWithPalette from "./colors";
import { lighten, linearGradient } from "polished";

const palette = {
  defaultBlack: "#1B1B1B",
  defaultWhite: "#fff",
  defaultLightBlue: "#DFE9EE",
  defaultOrange: "#F07E2660",
  defaultViolet: "#D6C8E060",

  green: {
    950: "#0a100f",
    900: "#13201d",
    800: "#26403a",
    700: "#396057",
    600: "#4d8074",
    500: "#609f92",
    400: "#80b3a7",
    300: "#9fc6bd",
    200: "#bfd9d3",
    100: "#dfece9",
    50: "#eff5f4",
  },

  lightBlue: {
    950: "#080e11",
    900: "#111c22",
    800: "#223744",
    700: "#325367",
    600: "#436e89",
    500: "#548aab",
    400: "#76a1bc",
    300: "#98b9cd",
    200: "#bbd0dd",
    100: "#dde8ee",
    50: "#eef3f7",
  },

  deepBlue: {
    950: "#090f11",
    900: "#111d22",
    800: "#223a44",
    700: "#335766",
    600: "#447588",
    500: "#5592aa",
    400: "#77a8bb",
    300: "#99bdcc",
    200: "#bbd3dd",
    100: "#dde9ee",
    50: "#eef4f6",
  },

  app: {
    background: "#F5F7F9",
  },
  login: {
    background: linearGradient({
      colorStops: ["#031653", "#94BAC9", "#DBECD9"],
      toDirection: "to right",
    }).backgroundImage,
    helpdeskBackground: linearGradient({
      colorStops: ["#D0DFE8", "#E0E9EE", "#CDDCE5"],
      toDirection: "to right",
    }).backgroundImage,
    helpdeskDisclaimerBackground: "transparent",
  },
};

const colors = colorsWithPalette(palette);

const liftGradient = linearGradient({
  colorStops: [
    baseTheme.colors["blue"][800],
    colors["deepBlue"][200],
    colors["green"][100],
  ],
  toDirection: "to right",
});

export const theme = extendTheme({
  ...baseTheme,
  fonts: {
    heading: "GeneralSans-Regular",
    body: "GeneralSans-Regular",
  },
  colors: {
    ...colorsWithPalette(palette),
    timelinePeriodColors: {
      boardSetsEarningCriteria: palette["green"][800],
      performancePeriod: palette["green"][700],
      optionVestingPeriod: palette["green"][600],
      ownershipRequirement: palette["green"][500],
      sharePurchases: palette["green"][400],
      measureOfAchievement: palette["green"][300],
      optionDelivery: palette["green"][200],
      savings: palette["deepBlue"][800],
      vestingPeriodRequirement: palette["deepBlue"][700],
      subscriptionPeriod: palette["deepBlue"][600],
      sharePurchasesEssp: palette["deepBlue"][500],
      shareDelivery: palette["deepBlue"][400],
      optionExpirationDate: palette["deepBlue"][300],
      dividendAdjustment: palette["deepBlue"][200],
    },

    chartColors: [
      palette["lightBlue"][500],
      colors["orange"][500],
      palette["green"][500],
      colors["violet"][500],
      colors["blue"][700],
      colors["blue"][400],
    ],

    alert: {
      background: baseTheme.colors["white"],
      color: "black",
      headerColor: baseTheme.colors["white"],
      headerBackground: linearGradient({
        colorStops: [
          baseTheme.colors["orange"][500],
          lighten(0.2, baseTheme.colors["orange"][500]),
        ],

        toDirection: "to right",
      }).backgroundImage,
    },

    graph: {
      background: "white",
      color: "black",
      headerBackground: linearGradient({
        colorStops: [
          baseTheme.colors["gray"][200],
          lighten(0.1, baseTheme.colors["gray"][200]),
        ],
        toDirection: "to right",
      }).backgroundImage,
    },
    neutral: {
      background: baseTheme.colors["gray.50"],
      color: "black",
      headerBackground: linearGradient({
        colorStops: [
          palette["lightBlue"][200],
          lighten(0.1, palette["lightBlue"][200]),
        ],
        toDirection: "to right",
      }).backgroundImage,
    },
    success: {
      background: palette["green"][50],
      color: "black",
      headerBackground: linearGradient({
        colorStops: [
          palette["green"][200],
          lighten(0.1, palette["green"][200]),
        ],
        toDirection: "to right",
      }).backgroundImage,
    },

    secondary: {
      background: colors["gray"][200],
      color: "black",
      headerBackground: linearGradient({
        colorStops: [colors["gray"][200], lighten(0.1, colors["gray"][200])],
        toDirection: "to right",
      }).backgroundImage,
    },

    outline: {
      border: "1px",
      borderColor: colors["primary"]["dark"],
      color: colors["blue"][800],
      _hover: {
        background: "transparent",
      },
      _active: {
        color: colors["blue"][600],
      },
    },

    navbar: {
      background: palette["lightBlue"][200],
      color: palette["lightBlue"][950],
    },

    brand: {
      highlight: colors["pink"][500],
      selected: colors["pink"][600],
      color: "black",
      background: palette["defaultWhite"],
      secondaryBackground: colors["blue"][500],
      tertiaryBackground: palette["defaultOrange"],

      sidebarHover: linearGradient({
        colorStops: [
          palette["deepBlue"][100],
          lighten(0.1, palette["deepBlue"][100]),
        ],
        toDirection: "to right",
      }).backgroundImage,

      sidebarActive: linearGradient({
        colorStops: [
          palette["deepBlue"][200],
          lighten(0.1, palette["deepBlue"][200]),
        ],
        toDirection: "to right",
      }).backgroundImage,

      heading1: colors["deepBlue"][700],
      dashboardWelcomeBox: {
        background: liftGradient?.backgroundImage,
        color: "white",
      },
    },

    input: {
      background: baseTheme.colors["white"],
      border: colors["gray"][200],
      color: "black",
    },
    link: colors["blue"][800],

    navigationButton: {
      color: "white",
      background: colors["blue"][800],
      hoverBackground: colors["blue"][700],
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: "transparent",
      textTransform: "none",
      _active: {
        bg: colors["blue"][900],
      },
    },
    inActiveNavigationButton: {
      color: "black",
      background: colors["deepBlue"][100],
      hoverBackground: colors["deepBlue"][300],
      borderRadius: "full",
      borderWidth: "1px",
      borderColor: colors["deepBlue"][200],
      textTransform: "none",
      _active: {
        bg: colors["deepBlue"][500],
      },
    },
    table: {
      subHeader: colors["blue"][50],
      evenBackground: colors["gray"][50],
      oddBackground: colors["white"],
      rowBorder: colors["gray"][300],
      footerBorder: colors["blue"][800],
      hover: colors["whiteAlpha"][700],
      header: colors["blue"][300],
      selectedBackground: linearGradient({
        colorStops: [
          colors["gray"][100],
          colors["gray"][50],
          colors["gray"][100],
        ],
        toDirection: "to right",
      }).backgroundImage,
      alertBackground: colors["deepBlue"][100],
      activeBackground: colors["gray"][100],
      summaryBackground: colors["gray"][200],
      summaryColor: colors["black"],
      color: colors["black"],
    },

    multiselect: {
      container: {
        border: "2px solid",
        background: "transparent",
        borderColor: "input.border",
      },
      valueContainer: {
        background: "input.background",
        paddingLeft: 2,
        width: "100%",
        minHeight: "40px",
      },
      control: {
        border: "2px solid",
        borderColor: "input.border",
        borderRadius: 6,
      },
      menu: {
        zIndex: 2,
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
        mt: "2px",
      },
      dropdownIndicator: {
        background: "white",
      },
    },
  },
});

export type AllSharesTheme = typeof theme;

export default theme;
